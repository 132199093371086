import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.HeaderPart background="white no-image">
                    <div className="headline">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Die Verfassung des Freistaates Bayern vom 26. Oktober 1946</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container.HeaderPart>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-12">
                            <Container.Facsimile
                                pdf="/facsimile/09_03_Zusätzliches Dokument_Faksimile_LA02a_Leseversion-1.pdf"
                                img="/images/Verfassung_alt.png"
                                alttext="Verfassung des Freistaats Bayern vom 26. Oktober 1946">
                                <figcaption>
                                    Verfassung des Freistaates Bayern vom 26. Oktober 1946
                                </figcaption>
                            </Container.Facsimile>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
